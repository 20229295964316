<template>
  <div>
    <div class="min-vh-100 container-box">
      <CRow class="no-gutters px-3 px-sm-0">
        <b-col cols="6" class="text-left mb-0">
          <h1 class="mr-sm-4 header-main text-uppercase my-2">
            รายการคำสั่งซื้อ
          </h1>
        </b-col>
        <b-col cols="6" class="text-right">
          <b-button v-b-toggle.sidebar-1 class="btn-filter">
            <font-awesome-icon
              icon="filter"
              title="filter-btn"
              class="text-white mr-0 mr-sm-1"
            />
            <span class="d-none d-sm-inline"
              >ค้นหา ({{
                countName +
                countOrderNo +
                countRole +
                countSku +
                countProductName +
                countStatus +
                countPaymentChannel +
                countStartdate +
                countEnddate +
                countpaymentStartDate +
                countpaymentEndDate
              }})</span
            >
          </b-button>
          <b-dropdown class="ml-1 p-1 lang-dd border-0" right>
            <b-dropdown-item-button @click="exportData('ExportOrders')" :disabled="selected.length == 0"
              >ส่งออกข้อมูล (แบบเต็ม)</b-dropdown-item-button
            >
            <b-dropdown-item-button @click="exportData('ExportOrdersShortly')" :disabled="selected.length == 0"
              >ส่งออกข้อมูล (แบบย่อ)</b-dropdown-item-button
            >
          </b-dropdown>
        </b-col>
      </CRow>
      <b-sidebar
        id="sidebar-1"
        title="ค้นหาแบบละเอียด"
        backdrop
        shadow
        backdrop-variant="dark"
        right
        ref="filterSidebar"
      >
        <div class="px-3 py-2">
          <div class="text-right">
            <button
              type="button"
              class="btn btn-link px-0"
              @click="onClearFilter()"
            >
              ล้างค่า
            </button>
          </div>

          <InputText
            class="mb-4"
            textFloat="เลขที่คำสั่งซื้อ"
            placeholder="เลขที่คำสั่งซื้อ"
            type="text"
            name="ordername"
            v-model="filter.OrderNo"
          />

          <InputSelect
            class="mb-4"
            title="ประเภทลูกค้า"
            name="roleId"
            :options="optionsRole"
            v-model="filter.roleId"
            @onDataChange="handleChangeRoleId"
            valueField="id"
            textField="name"
          />

          <InputText
            class="mb-4"
            textFloat="ค้นหาชื่อลูกค้า"
            placeholder="ค้นหาชื่อลูกค้า"
            type="text"
            name="cusname"
            v-model="filter.CustomerName"
          />

          <InputText
            class="mb-4"
            textFloat="SKU"
            placeholder="SKU"
            type="text"
            name="sku"
            v-model="filter.Sku"
          />

          <InputText
            class="mb-4"
            textFloat="ค้นหาชื่อสินค้า"
            placeholder="ค้นหาชื่อสินค้า"
            type="text"
            name="proname"
            v-model="filter.ProductName"
          />
          <InputSelect
            class="mb-4"
            title="สถานะคำสั่งซื้อ"
            name="payment"
            v-bind:options="statusList"
            valueField="id"
            textField="name"
            v-model="statusSelected"
            @onDataChange="handleChangeStatusChannel"
          />
          <InputSelect
            class="mb-4"
            title="ช่องทางการชำระเงิน"
            name="payment"
            v-bind:options="paymentChannelList"
            valueField="id"
            textField="name"
            v-model="paymentChanelSelected"
            @onDataChange="handleChangePaymentChannel"
          />

          <label class="label-text">วันที่ชำระเงินเริ่มต้น</label>
          <datetime
            placeholder="กรุณากรอกวันที่"
            class="date-picker mb-3"
            format="dd MMM yyyy"
            value-zone="local"
            v-model="filter.paymentStartDate"
          ></datetime>

          <label class="label-text">วันที่ชำระเงินสิ้นสุด</label>
          <datetime
            placeholder="กรุณากรอกวันที่"
            class="date-picker"
            format="dd MMM yyyy"
            value-zone="local"
            v-model="filter.paymentEndDate"
          ></datetime>

          <div class="text-center">
            <span class="text-danger" v-if="errorDatePayment">
              วันสิ้นสุดต้องมากกว่าวันเริ่มต้น
            </span>
          </div>

          <label class="label-text mt-3">วันที่สร้างคำสั่งซื้อเริ่มต้น</label>
          <datetime
            placeholder="กรุณากรอกวันที่"
            class="date-picker mb-3"
            format="dd MMM yyyy"
            value-zone="local"
            v-model="filter.startDate"
          ></datetime>

          <label class="label-text">วันที่สร้างคำสั่งซื้อสิ้นสุด</label>
          <datetime
            placeholder="กรุณากรอกวันที่"
            class="date-picker"
            format="dd MMM yyyy"
            value-zone="local"
            v-model="filter.endDate"
          ></datetime>

          <div class="text-center">
            <span class="text-danger" v-if="errorDate">
              วันสิ้นสุดต้องมากกว่าวันเริ่มต้น
            </span>
          </div>

          <div class="text-center mt-4">
            <button
              type="button"
              class="btn btn-purple button"
              @click="getDataByStatus"
            >
              ค้นหา
            </button>
          </div>
        </div>
      </b-sidebar>
      <!-- <b-row class="no-gutters px-3 px-sm-0 mt-2 overflow-auto">
        <b-col class="">
          <b-button-group class="btn-group-status">
            <b-button
              v-for="(item, index) in statusList"
              :key="index"
              @click="getDataByOrderStatus(item.name, item.id)"
              :class="{ menuactive: isActive(item.name) }"
              >{{ item.name }} ({{ item.count }})</b-button
            >
          </b-button-group>
        </b-col>
      </b-row> -->
      <div class="mt-3 bg-white px-0 pb-3">
        <b-row class="no-gutters">
          <b-col>
            <b-table
              class="table-list"
              striped
              responsive
              hover
              :items="items"
              :fields="fields"
              :busy="isBusy"
              show-empty
              empty-text="ไม่พบข้อมูล"
            >
              <template v-slot:head(ids)="data">
                <b-form-checkbox
                  size="lg"
                  class="ml-3"
                  :ref="data.field.key"
                  v-model="selectedAll"
                  @change="handleSelectedAll"
                ></b-form-checkbox>
              </template>
              <template v-slot:cell(ids)="data">
                <b-form-checkbox
                  size="lg"
                  class="ml-3"
                  :value="data.item.id"
                  v-model="selected"
                  @input="handleItem($event, data.item.id, data.item.price)"
                ></b-form-checkbox>
              </template>
              <template v-slot:cell(firstName)="data">
                <span>{{ data.item.firstName }} {{ data.item.lastName }}</span>
              </template>
              <template v-slot:cell(createdTime)="data">
                <span>
                  {{
                    $moment(data.item.createdTime).format($formatDateTimeFull)
                  }}
                </span>
              </template>
              <template v-slot:cell(checkOutTime)="data">
                <span v-if="data.item.orderStatusId != 2">
                  {{
                    $moment(data.item.checkOutTime).format($formatDateTimeFull)
                  }}
                </span>
                <span v-else>-</span>
              </template>
              <template v-slot:cell(orderStatus)="data">
                <div
                  v-if="
                    data.item.orderStatusId == 10 ||
                    data.item.orderStatusId < 5 ||
                    data.item.orderStatusId == 14
                  "
                  class="text-warning"
                >
                  {{ data.item.orderStatus }}
                </div>
                <div
                  v-else-if="
                    data.item.orderStatusId == 5 ||
                    data.item.orderStatusId == 11
                  "
                  class="text-success"
                >
                  {{ data.item.orderStatus }}
                </div>
                <div v-else class="text-danger">
                  {{ data.item.orderStatus }}
                </div>
              </template>
              <template v-slot:cell(grandTotal)="data">
                <p class="m-0">
                  ฿ {{ data.item.grandTotal | numeral("0,0.00") }}
                </p>
              </template>
              <template v-slot:cell(point)="data">
                <p class="m-0" v-if="data.item.orderStatusId == 5">
                  {{ data.item.point | numeral("0,0.00") }}
                </p>
                <p class="m-0" v-else>รอคำสั่งซื้อสมบูรณ์</p>
              </template>
              <template v-slot:cell(cashback)="data">
                <p class="m-0" v-if="data.item.orderStatusId == 5">
                  ฿ {{ data.item.cashback | numeral("0,0.00") }}
                </p>
                <p class="m-0" v-else>รอคำสั่งซื้อสมบูรณ์</p>
              </template>
              <template v-slot:cell(qty)="data">
                <p class="m-0">
                  {{ data.item.qty | numeral("0,0") }}
                </p>
              </template>
              <template v-slot:cell(orderNo)="data">
                <div class="d-flex justify-content-center">
                  <router-link :to="'/order/details/' + data.item.id">
                    {{ data.item.orderNo }}
                  </router-link>
                </div>
              </template>
              <!-- <template v-slot:cell(action)="data">
                <div
                  class="d-flex justify-content-center"
                  v-if="data.item.isReturnable"
                >
                  <router-link :to="'/order/return/' + data.item.orderNo">
                    <b-button variant="link" class="px-1 py-0">
                      ขอคืนสินค้า
                    </b-button>
                  </router-link>
                </div>
              </template> -->
              <template v-slot:table-busy>
                <div class="text-center text-black my-2">
                  <b-spinner class="align-middle"></b-spinner>
                  <strong class="ml-2">Loading...</strong>
                </div>
              </template>
            </b-table>
          </b-col>
        </b-row>
        <b-row class="no-gutters px-3 px-sm-0">
          <b-col
            class="form-inline justify-content-center justify-content-sm-between"
          >
            <div class="d-sm-flex m-3">
              <b-pagination
                v-model="filter.PageNo"
                :total-rows="rows"
                :per-page="filter.PerPage"
                class="m-md-0"
                @change="pagination"
                align="center"
              ></b-pagination>
            </div>

            <b-form-select
              class="mr-sm-3 select-page"
              v-model="filter.PerPage"
              @change="hanndleChangePerpage"
              :options="pageOptions"
            ></b-form-select>
          </b-col>
        </b-row>
      </div>
    </div>
    <ModalLoading ref="modalLoading" :hasClose="false" />
    <ModalAlert ref="modalAlert" :text="modalMessage" />
  </div>
</template>

<script>
import axios from "axios";
import * as moment from "moment/moment";

import InputText from "@/components/inputs/InputText";
import InputSelect from "@/components/inputs/InputSelect";
import ModalLoading from "@/components/modal/alert/ModalLoading";
import ModalAlert from "@/components/modal/alert/ModalAlert";

export default {
  name: "OrderIndex",
  components: {
    InputText,
    InputSelect,
    ModalLoading,
    ModalAlert
  },
  data() {
    return {
      statusList: [],
      paymentChannelList: [],
      paymentChanelSelected: 0,
      statusSelected: 0,
      modalMessage: "",
      activeItem: "",
      requestDeleteUser: {
        userId: null,
      },
      filterSubmit: {
        PageNo: 1,
        PerPage: 10,
        CustomerName: "",
        ProductName: "",
        Sku: "",
        startDate: "",
        endDate: "",
        paymentStartDate: "",
        paymentEndDate: "",
        OrderNo: "",
        Status: [],
        PaymentChanel: [],
        OrderId: [],
        roleId: 0,
      },
      fields: [
        { key: "ids", label: "เลือก", class: "w-50px text-nowrap" },
        {
          key: "orderNo",
          label: "เลขที่คำสั่งซื้อ",
          class: "w-100px",
        },
        {
          key: "createdTime",
          label: "วันที่สร้างรายการ",
          class: "w-100px",
        },
        {
          key: "checkOutTime",
          label: "วันที่ชำระเงิน",
          class: "w-100px",
        },
        {
          key: "firstName",
          label: "ผู้ซื้อ",
          class: "w-100px",
        },
        {
          key: "paymentType",
          label: "ช่องทางการชำระเงิน",
          class: "w-100px",
        },
        {
          key: "grandTotal",
          label: "จำนวนเงิน",
          class: "w-100px",
        },
        {
          key: "qty",
          label: "จำนวน",
        },
        {
          key: "orderStatus",
          label: "สถานะ",
          class: "w-100px",
        },
        // {
        //   key: "action",
        //   label: "",
        //   class: "w-100px",
        // },
        // {
        //   key: "point",
        //   label: "คะแนน",
        //   class: "w-100px",
        // },
        // {
        //   key: "cashback",
        //   label: "เงินคืน",
        //   class: "w-100px",
        // },
        // {
        //   key: "id",
        //   label: "",
        //   class: "w-100px",
        // },
      ],
      items: [],
      isBusy: true,
      rows: 0,
      filter: {
        PageNo: 1,
        PerPage: 10,
        CustomerName: "",
        ProductName: "",
        Sku: "",
        startDate: "",
        endDate: "",
        paymentStartDate: "",
        paymentEndDate: "",
        OrderNo: "",
        Status: [],
        PaymentChanel: [],
        roleId: 0,
      },
      pageOptions: [
        { value: 10, text: "10 / หน้า" },
        { value: 30, text: "30 / หน้า" },
        { value: 50, text: "50 / หน้า" },
        { value: 100, text: "100 / หน้า" },
      ],
      optionsRole: [
        { id: 0, name: "ทั้งหมด" },
        { id: 4, name: "ลูกค้าทั่วไป" },
        { id: 1, name: "ร้านค้าทั่วไป" },
      ],
      paymentChannelFilter: {
        perPage: 10,
        pageNo: 1,
        search: "",
        status: [],
        sortByOrderOrId: 1,
      },
      totalRowMessage: "",
      isDisable: false,
      selected: [],
      selectedAll: false,
      checkAll: false,
      selectAllCb: false,
      errorDate: false,
      errorDatePayment: false,
    };
  },
  computed: {
    countName: function () {
      var count = 0;
      if (this.filter.CustomerName != "") count += 1;
      else if (count > 0) count -= 1;
      return count;
    },
    countOrderNo: function () {
      var count = 0;

      if (this.filter.OrderNo != "") count += 1;
      else if (count > 0) count -= 1;
      return count;
    },
    countSku: function () {
      var count = 0;
      if (this.filter.Sku != "") count += 1;
      else if (count > 0) count -= 1;
      return count;
    },
    countProductName: function () {
      var count = 0;

      if (this.filter.ProductName != "") count += 1;
      else if (count > 0) count -= 1;
      return count;
    },
    countStatus: function () {
      var count = 0;
      if (this.filter.Status.length != 0) count += 1;
      else if (count > 0) count -= 1;
      return count;
    },
    countPaymentChannel: function () {
      var count = 0;
      if (this.filter.PaymentChanel.length != 0) count += 1;
      else if (count > 0) count -= 1;
      return count;
    },
    countEnddate: function () {
      var count = 0;

      if (this.filter.endDate != "") count += 1;
      else if (count > 0) count -= 1;
      return count;
    },
    countStartdate: function () {
      var count = 0;
      if (this.filter.startDate != "") count += 1;
      else if (count > 0) count -= 1;
      return count;
    },
    countpaymentEndDate: function () {
      var count = 0;

      if (this.filter.paymentEndDate != "") count += 1;
      else if (count > 0) count -= 1;
      return count;
    },
    countpaymentStartDate: function () {
      var count = 0;
      if (this.filter.paymentStartDate != "") count += 1;
      else if (count > 0) count -= 1;
      return count;
    },
    countRole: function () {
      var count = 0;
      if (this.filter.roleId > 0) count += 1;
      else if (count > 0) count -= 1;
      return count;
    },
  },
  created: async function () {
    await this.getStatusList();
    await this.getPaymentChannelList();
    await this.getRoleList();
    await this.getList();
    this.activeItem = "ทั้งหมด";
  },
  methods: {
    handleSelectedAll: async function (e) {
      this.selectedAll = e;
      if (e) {
        this.$refs.modalLoading.show();
        await this.getAllData();
      } else {
        this.selected = [];
      }
      this.filterSubmit.OrderId = [];
    },
    getAllData: async function () {
      let filterAll = {
        PageNo: 1,
        PerPage: -1,
        CustomerName: "",
        ProductName: "",
        Sku: "",
        startDate: "",
        endDate: "",
        paymentStartDate: "",
        paymentEndDate: "",
        OrderNo: "",
        Status: [],
        PaymentChanel: [],
        roleId: 0
      };

      let resData = await this.$callApi(
        "post",
        `${this.$baseUrl}/api/Transaction/Orders`,
        null,
        this.$headers,
        filterAll
      );

      if (resData.result == 1) {
        this.selected = [];
        let all = [];
        all = resData.detail;
        if (all.dataList.length > 0) {
          await all.dataList.forEach((element, index) => {
            this.selected.push(element.id);
          });
        }
      }
      this.$refs.modalLoading.hide();
    },
    getRoleList: async function() {
      let status = await this.$callApi(
        "get",
        `${this.$baseUrl}/api/transaction/Role`,
        null,
        this.$headers,
        null
      );
      if (status.result == 1) {
        this.optionsRole = status.detail;
      }
    },
    getPaymentChannelList: async function() {
      let paymentChannel = await this.$callApi(
        "post",
        `${this.$baseUrl}/api/PaymentChannel/List`,
        null,
        this.$headers,
        this.paymentChannelFilter
      );
      if (paymentChannel.result == 1) {
        let list = [{ id: 0, name: `กรุณาเลือกช่องทางการชำระเงิน` }];
        let datalist = paymentChannel.detail.dataList;
        this.paymentChannelList = list.concat(datalist);
      }
    },
    getStatusList: async function() {
      let status = await this.$callApi(
        "get",
        `${this.$baseUrl}/api/Transaction/OrderStatus`,
        null,
        this.$headers,
        null
      );

      let list = [{ id: 0, name: `ทั้งหมด`, sortOrder: 0 }];
      if (status.result == 1) {
        this.statusList = list.concat(status.detail);
      }
    },
    getList: async function () {
      this.isBusy = true;
      let filter = { ...this.filter };
      let resData = await this.$callApi(
        "post",
        `${this.$baseUrl}/api/Transaction/Orders`,
        null,
        this.$headers,
        filter
      );
      if (resData.result == 1) {
        this.items = resData.detail.dataList;
        this.rows = resData.detail.count;
        //this.statusList = resData.detail.overviewCount;

        this.isBusy = false;
        this.$isLoading = true;
      }
    },
    getDataByStatus(status) {
      if (
        this.filter.startDate > this.filter.endDate &&
        this.filter.endDate != ""
      ) {
        this.errorDate = true;
        return;
      }

      this.errorDate = false;

      if (
        this.filter.paymentStartDate > this.filter.paymentEndDate &&
        this.filter.paymentEndDate != ""
      ) {
        this.errorDatePayment = true;
        return;
      }

      this.errorDatePayment = false;

      this.filter.PageNo = 1;
      this.$refs.filterSidebar.hide(true);
      this.getList();
    },
    handleItem(e, id) {
      if (e) {
        // let findId = this.selected.findIndex((e) => {
        //   return e === id;
        // });
        // if (findId === -1) {
        //   this.selected.push(id);
        // }
      } else {
        // let find = this.selected.findIndex((e) => {
        //   return e === id;
        // });
        // if (find !== -1) {
        //   this.selected.splice(find, 1);
        // }
      }
      this.filterSubmit.OrderId = this.selected;
    },
    getDataByOrderStatus(status, id) {
      this.activeItem = status;
      this.filter.Status = [];
      if (id != 0) this.filter.Status.push(id);
      this.getList();
    },
    isActive: function (menuItem) {
      return this.activeItem == menuItem;
    },
    pagination(Page) {
      window.scrollTo(0, 0);
      this.filter.PageNo = Page;
      this.getList();
    },
    hanndleChangePerpage(value) {
      this.filter.PageNo = 1;
      this.filter.PerPage = value;
      this.getList();
    },
    handleSearch(e) {
      if (e.keyCode === 13) {
        this.filter.PageNo = 1;
        this.getList();
      }
    },
    btnSearch() {
      this.filter.PageNo = 1;
      this.getList();
    },
    hanndleStatusList(value) {
      this.filter.PageNo = 1;
      this.filter.statusId = value;
      this.getList();
    },
    handleChangePaymentChannel: async function (value) {
      this.filter.PaymentChanel = [];
      if (value != 0) {
        this.paymentChanelSelected = value;
        this.filter.PaymentChanel.push(value);
      }
    },
    handleChangeStatusChannel: async function (value) {
      this.filter.Status = [];
      if (value != 0) {
        this.statusSelected = value;
        this.filter.Status.push(value);
      }
    },
    checkAllSelect() {
      if (!this.selectAllCb) {
        this.filter.Status = [];
      } else {
        var list = this.statusList.slice(1);
        for (const item in list) {
          this.filter.Status.push(list[item].id);
        }
      }
    },
    checkStatusLength() {
      if (this.filter.Status.length == 11) {
        this.selectAllCb = true;
      } else {
        this.selectAllCb = false;
      }
    },
    handleChangeRoleId: async function(value) {
      this.filter.roleId = value;
    },
    onClearFilter() {
      this.filter.PageNo = 1;
      this.filter.startDate = "";
      this.filter.endDate = "";
      this.filter.paymentStartDate = "";
      this.filter.paymentEndDate = "";
      this.filter.CustomerName = "";
      this.filter.ProductName = "";
      this.filter.Sku = "";
      this.filter.OrderNo = "";
      this.paymentChanelSelected = 0;
      this.filter.PaymentChanel = [];
      this.statusSelected = 0;
      this.filter.Status = [];
      this.filter.roleId = 0;

      this.errorDate = false;
      this.errorDatePayment = false;
      this.$refs.filterSidebar.hide(true);
      this.getList();
    },
    exportData: async function (method) {
      this.$refs.modalLoading.show();
      let filter = {
        ...this.filter,
        OrderId: this.filterSubmit.OrderId,
      };
      await axios({
        url: `${this.$baseUrl}/api/transaction/${method}`,
        method: "post",
        headers: this.$headers,
        responseType: "blob",
        data: filter,
      })
        .then(async (response) => {
          this.$refs.modalLoading.hide();
          if (response.data.type == "application/json") {
            const responseData = await (response?.data)?.text();
            const responseJson = JSON.parse(responseData);

            this.modalMessage = responseJson.message;
            this.$refs.modalAlert.show();
            setTimeout(() => { this.$refs.modalAlert.hide(); }, 3000);
          } else {
            var fileURL = window.URL.createObjectURL(new Blob([response.data]));
            var fileLink = document.createElement("a");
            var dateExcel = moment().format("DDMMYYYYhhmmss");

            fileLink.href = fileURL;
            fileLink.setAttribute(
              "download",
              `Order-List-` + dateExcel + `.xlsx`
            );
            document.body.appendChild(fileLink);
            fileLink.click();
          }
        })
        .catch((error) => {
          console.log('error', error);
        });
    },
  },
};
</script>

<style scoped>
::v-deep .dropdown-menu-right {
  padding: 0rem !important;
}

::v-deep .dropdown-item {
  padding: 0.75rem 1.25rem !important;
}

::v-deep .lang-dd button:focus {
  background-color: #fff !important;
  color: #4f5d73;
}

::v-deep .lang-dd ul {
  width: 100px;
}

.menuactive {
  color: #ffb300 !important;
}
</style>
